import { useNavigate } from "react-router-dom";

const ThankYou = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full h-[100vh] flex items-center justify-center">
      <div className="w-[90%] md:w-[60%] text-center">
        <h2 className="text-[150px]">🎉</h2>
        <h4 className="text-3xl text-black font-semibold my-2">
          Thank you for buying Subscription
        </h4>
        <p className="text-md text-gray-400">
          You can now start using the app & use the awesome AI chat features
          with your team members based on your subscription
        </p>

        <button
          onClick={() => navigate("/chats")}
          className="bg-green-600 text-white h-12 mt-6 px-8 text-md font-medium rounded-md cursor-pointer"
        >
          Start using app
        </button>
      </div>
    </div>
  );
};

export default ThankYou;
