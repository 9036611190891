import { createContext, useEffect, useState } from "react";
import axiosInstance from "../utils/axios-instance.ts";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ParseErrors } from "../utils/parse-errors.ts";
import { UserModelType } from "../types/user-types.ts";
import PageLoader from "../components/page-loader.tsx";

export type User = UserModelType;

type LoginUserInterface = {
  isLoggedIn: boolean;
  data: {
    token: string;
    user: User;
  };
};

type ValueAuthContext = {
  user: User | undefined;
  isLoading: boolean;
  UpdateLoginData: (loginData: any) => void;
  HandleUserLogOut: () => void;
};

const ProtectedPages = ["/chats", "/user-setting", "/thank-you"];
const unProtectedPages = ["/login", "/signup"];

export const AuthContext = createContext<ValueAuthContext | undefined>(
  undefined
);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [user, setUser] = useState<LoginUserInterface | undefined>();
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const pathName = document.location.pathname;

  const UpdateLoginData = (loginData: any) => {
    setUser(loginData);
  };

  // ---------------------------- [ IF NO SESSIONS ] ----------------------------
  const HandleNoSession = (errorMessage) => {
    if (ProtectedPages?.includes(pathName) && !loading) {
      navigation("/login");
      toast.error(errorMessage || "Session Expired");
    }
    setUser(null);
    setLoading(false);
  };

  // ------------------------- [ UPDATE USER SESSION ] ------------------------
  const UpdateUserSession = async () => {
    setLoading(true);
    try {
      const res = await axiosInstance.get("/auth/login");
      if (res.data?.isLoggedIn === false) {
        HandleNoSession(res.data.message);
      } else {
        setUser(res.data);
        setLoading(false);
      }
    } catch (error) {
      if (
        error?.response?.data?.isLoggedIn === false ||
        !error?.response?.status
      ) {
        HandleNoSession(error?.response?.data?.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    UpdateUserSession();
  }, [pathName]);

  // ----------------------------- [ USER LOGOUT ] ----------------------------------
  const HandleUserLogOut = async () => {
    setLoading(true);
    try {
      await axiosInstance.get("/auth/logout");
      toast.success("Logged out successfully");
      navigation("/login");
      setLoading(false);
      setUser(null);
    } catch (error) {
      setLoading(false);
      toast.error(ParseErrors(error));
    }
  };

  // --------------------- [ IF USER LOGGED IN REDIRECT ] -------------------------

  useEffect(() => {
    if (
      !loading &&
      user?.data?.user?.id &&
      unProtectedPages?.includes(pathName)
    ) {
      navigation("/");
    }
  }, [loading, user, navigation, pathName]);
  

  if (!user?.data?.user?.id && loading) {
    return <PageLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        UpdateLoginData,
        HandleUserLogOut,
        isLoading: loading,
        user: user?.data?.user as User,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
