const PageLoader = () => {
  return (
    <div className="w-full h-[100vh] flex items-center justify-center">
      <div className="flex items-center justify-center animate-pulse">
        <h2 className="text-[100px]">👋</h2>
        <img
          src="/assets/images/coachai.png"
          className="w-[70%]  md:w-[300px] object-cover"
        />
      </div>
    </div>
  );
};

export default PageLoader;
